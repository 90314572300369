import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { circularProgress } from "@/main";
import { useUser } from "@/context/useUser";
import { isAuthError } from "@/firebaseConfig";

export default function FinishEmailSignIn() {
  const navigate = useNavigate();
  const { firebase, setOtherTakeoverCode } = useUser();
  const [params] = useSearchParams();
  const [handled, setHandled] = useState(false);
  const email = params.get("email");
  const anonUid = params.get("anonUid");
  const takeoverCode = params.get("takeoverCode");
  const thenNavigate = params.get("thenNavigate") || "/";
  const isMobileAuth = params.get("mobile") === "true";

  useEffect(() => {
    if (handled || !firebase) {
      return;
    }
    setHandled(true);

    // Handle the email sign in process
    async function handleEmailSignIn() {
      if (!firebase) {
        throw new Error("Firebase is not initialized");
      }
      const { isSignInWithEmailLink, signInWithEmailLink } = firebase.authPackage;
      if (!isSignInWithEmailLink(firebase.auth, window.location.href)) {
        navigate(thenNavigate);
        return;
      }

      try {
        // For mobile, just forward the sign-in link
        if (isMobileAuth) {
          const encodedLink = encodeURIComponent(window.location.href);
          window.location.href = `sportsball://auth-complete?link=${encodedLink}`;
          return;
        }

        // Only do web auth flow if not mobile
        // Ensure we have the required parameters
        if (!email || !takeoverCode || !anonUid) {
          throw new Error("Missing required params");
        }
        console.info("setting other takeover code", { anonUid, takeoverCode });
        // set these so that App.tsx will complete takeover with page navigation
        setOtherTakeoverCode({ anonUid, takeoverCode, email });

        await signInWithEmailLink(firebase.auth, email, window.location.href);
        navigate(thenNavigate);
      } catch (err) {
        // Handle expired or invalid links gracefully
        if (isAuthError(err, firebase) && err.code === "auth/invalid-action-code") {
          alert(
            "Invalid or expired link. This often happens when you click on a link from an old email. Please check for a newer email from us or try to sign in again."
          );
          navigate(thenNavigate);
          return;
        }
        throw err;
      }
    }

    handleEmailSignIn().catch(alert);
    // some of these dependencies will never change but any mechanism to have eslint ignore them is not worth it
  }, [handled, thenNavigate, email, firebase, navigate, takeoverCode, isMobileAuth, setOtherTakeoverCode, anonUid]);

  return circularProgress();
}
