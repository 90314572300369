import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddIcon from "@mui/icons-material/Add";

import { isPickemGame, isSquaresGame } from "@sportsball/shared";

import QuestionsGame from "./QuestionsGame";
import SquaresGame from "./squares/SquaresGame";
import Countdown from "@/components/Countdown";
import GameTitle from "@/components/GameTitle";
import ConfirmDialog from "@/components/ConfirmDialog";
import FullSizeCard from "@/components/FullSizeCard";
import ShareDialog from "@/components/ShareDialog";
import { GameContainerProps } from "@/components/GameContainer";

import { useUser } from "@/context/useUser";
import { deleteGame, wakeUpFunctions } from "@/cloudFunctions";

export default function Game() {
  const navigate = useNavigate();
  const { type, game, gameId, gameOn, pregameEntries, roomData } = useOutletContext<GameContainerProps>();
  const { firebase, user } = useUser();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const uid = user?.uid;

  const hasPregameEntry = uid && pregameEntries && Object.values(pregameEntries).find((entry) => entry!.uid === uid);

  async function performDelete() {
    if (!firebase) return;
    try {
      await deleteGame(firebase, { gameId });
      setShowDeleteDialog(false);
      navigate(`/`);
    } catch (error) {
      alert(error);
    }
  }

  const { startTimestamp } = game;
  return (
    <>
      <FullSizeCard>
        <Stack spacing={3}>
          {/* Header Section */}
          <Box
            sx={{
              background: (theme) =>
                `linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
              color: "white",
              p: 3,
              borderRadius: 2,
              mb: 2,
            }}
          >
            <GameTitle game={game} navButtons={true} />
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                opacity: 0.9,
              }}
            >
              {roomData && <Typography>In Room: {roomData.title}</Typography>}
              {game.chipsPerEntry && <Typography>Chips per entry: {game.chipsPerEntry}</Typography>}
              {game.chipsPerEntry && roomData && (
                <Typography>Your chip balance: {roomData.chipBalance ?? 0}</Typography>
              )}
              {game.description}
            </Typography>
          </Box>

          {/* Countdown Section */}
          {!gameOn && startTimestamp && (
            <Box sx={{ textAlign: "center" }}>
              <Countdown startTimestamp={startTimestamp} />
            </Box>
          )}

          {/* Game Content */}
          <Box sx={{ my: 2 }}>
            {isPickemGame(game) && type === "Pickem" && (
              <QuestionsGame gameId={gameId} game={game} gameOn={gameOn} pregameEntries={pregameEntries} />
            )}
            {isSquaresGame(game) && type === "Squares" && (
              <SquaresGame gameId={gameId} game={game} roomData={roomData} pregameEntries={pregameEntries} />
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Action Buttons */}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Button variant="outlined" startIcon={<HomeIcon />} onClick={() => navigate("/")}>
              Home
            </Button>

            {game.type === "Pickem" && !gameOn && (
              <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate("join")}>
                {hasPregameEntry ? "Add Entry" : "Join Game"}
              </Button>
            )}

            <Button variant="contained" startIcon={<IosShareIcon />} onClick={() => setShowShareDialog(true)}>
              Share
            </Button>

            {game.uid === uid && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  // anticipating the deleteGame call we warm up the bottleneck function
                  wakeUpFunctions(firebase);
                  setShowDeleteDialog(true);
                }}
              >
                Delete Game
              </Button>
            )}
          </Stack>
        </Stack>
      </FullSizeCard>

      {/* Dialogs */}
      <ConfirmDialog
        prompt="Are you sure you want to delete this game?"
        confirmText="Delete"
        cancelText="Cancel"
        open={showDeleteDialog}
        confirmFn={() => void performDelete()}
        closeDialog={() => setShowDeleteDialog(false)}
      />
      <ShareDialog gameId={gameId} game={game} open={showShareDialog} closeDialog={() => setShowShareDialog(false)} />
    </>
  );
}
