// Footer.tsx
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import GridOnIcon from "@mui/icons-material/GridOn";
import { FaDiscord } from "react-icons/fa";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        p: { xs: 2, sm: 3 },
      }}
    >
      <Grid container spacing={3} justifyContent="space-between">
        <Grid>
          <Typography variant="h6" sx={{ mb: 1 }} fontFamily="Nico Moji">
            MLB Spring Training 2025
          </Typography>
          <Typography variant="body2">
            Brought to you by <Link href="https://joinsportsball.com">Sportsball</Link>
          </Typography>
        </Grid>

        {/* Game Links */}
        <Grid>
          <Box>
            <Link
              href="/games/new/football-squares"
              color="inherit"
              underline="none"
              sx={{ display: "block", mb: 0.5 }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ width: 20, display: "flex", justifyContent: "center" }}>
                  <GridOnIcon sx={{ fontSize: 20 }} />
                </Box>
                Host your own squares game on Sportsball
              </Box>
            </Link>
            <Link
              href="https://discord.gg/Yg9bwsXMkB"
              color="inherit"
              underline="none"
              sx={{ display: "block", mb: 0.5 }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ width: 20, display: "flex", justifyContent: "center" }}>
                  <FaDiscord size={20} />
                </Box>
                Join our Discord community
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Typography variant="body2">&copy; {new Date().getFullYear()} Matt Wormley</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
