import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import AddIcon from "@mui/icons-material/Add";

import { BasicEntryData, SquaresGameData } from "@sportsball/shared";

import { LazyFirebase } from "@/context/useFirebasePreUser";
import { useUser } from "@/context/useUser";

import ConfirmDialog from "@/components/ConfirmDialog";
import SquaresBoardEditor from "@/components/squares/SquaresBoardEditor";
import SquaresBoard from "@/components/squares/SquaresBoard";

import { lockSquaresGame } from "@/cloudFunctions";
import { GameRoomData } from "@/components/GameContainer";

const _100BoardScores = [...Array(10).keys()];

const _goodScores = [0, 7, 3, 4];
const _badScores = [2, 5, 8, 9];
const _middlePair = { multi: [1, 6] };

// Fisher-Yates shuffle
function _shuffleArray<T>(a: T[]) {
  const shuffled = [...a];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

export function generateTargetScores(size: 25 | 100) {
  if (size === 25) {
    // randomly pair up good and bad scores for both home and away
    const awayFourPairs = _shuffleArray(_badScores).map((bad, i) => ({ multi: [_goodScores[i], bad] }));
    const homeFourPairs = _shuffleArray(_badScores).map((bad, i) => ({ multi: [_goodScores[i], bad] }));
    // shuffle in the one _middlePair into away and home scores
    const away = _shuffleArray([...awayFourPairs, _middlePair]);
    const home = _shuffleArray([...homeFourPairs, _middlePair]);
    return { away, home };
  }
  return { away: _shuffleArray(_100BoardScores), home: _shuffleArray(_100BoardScores) };
}

function _lockSquaresGame({
  gameId,
  game,
  firebase,
}: {
  gameId: string;
  game: SquaresGameData;
  firebase: LazyFirebase;
}) {
  const targetScores = generateTargetScores(game.size);
  lockSquaresGame(firebase, { gameId, targetScores }).catch(alert);
}

export default function SquaresGame({
  gameId,
  game,
  roomData,
  pregameEntries,
}: {
  gameId: string;
  game: SquaresGameData;
  roomData: GameRoomData | undefined;
  pregameEntries?: Partial<Record<string, BasicEntryData>>;
}) {
  const { firebase, user } = useUser();
  const [editingGame, setEditingGame] = useState(false);
  const [initials, setInitials] = useState<string | undefined>();
  const [canJoin, setCanJoin] = useState(false);
  const [showConfirmLockGame, setShowConfirmLockGame] = useState(false);
  const uid = user?.uid;
  const { size } = game;

  const gameLocked = Boolean(game.targetScores);
  const pregameCellCount = Object.keys(pregameEntries ?? {}).length;

  useEffect(() => {
    setCanJoin(!gameLocked && pregameCellCount < size);
  }, [pregameCellCount, size, gameLocked]);

  useEffect(() => {
    if (gameLocked && editingGame) {
      setEditingGame(false);
    }
  }, [gameLocked, editingGame]);

  useEffect(() => {
    if (initials === undefined && user?.type === "signedIn") {
      setInitials(user.preferredInitials);
    }
  }, [user, initials]);

  const hasPregameEntry = uid && pregameEntries && Object.values(pregameEntries).find((entry) => entry!.uid === uid);

  return (
    <>
      <Box onClick={gameLocked ? undefined : () => setEditingGame(true)} sx={gameLocked ? {} : { cursor: "pointer" }}>
        <SquaresBoard game={game} pregameEntries={pregameEntries} />
      </Box>
      {!gameLocked && (
        <Stack direction="row" justifyContent="center" gap={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setEditingGame(true)}
            disabled={editingGame || gameLocked}
          >
            {hasPregameEntry ? "Edit Entries" : "Join Game"}
          </Button>
          {firebase && game.uid === uid && (
            <Button
              variant="contained"
              onClick={() => {
                if (canJoin) {
                  setShowConfirmLockGame(true);
                } else {
                  _lockSquaresGame({ gameId, game, firebase });
                }
              }}
            >
              LOCK GAME
            </Button>
          )}
        </Stack>
      )}
      {firebase && (
        <ConfirmDialog
          prompt="This game still has empty squares. Are you sure you want to lock this game?"
          confirmText="Lock"
          cancelText="Cancel"
          open={showConfirmLockGame}
          confirmFn={() => _lockSquaresGame({ gameId, game, firebase })}
          closeDialog={() => setShowConfirmLockGame(false)}
        />
      )}
      <SquaresBoardEditor
        game={game}
        roomData={roomData}
        gameId={gameId}
        pregameEntries={pregameEntries}
        show={editingGame}
        doneEditing={() => setEditingGame(false)}
        initials={initials ?? ""}
        setInitials={setInitials}
      />
    </>
  );
}
