import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

import { RoomData, SomeGameData } from "@sportsball/shared";

import FullSizeCard from "@/components/FullSizeCard";
import GameCard from "@/components/GameCard";

import { useUser } from "@/context/useUser";
import { useLoggedInFirestoreObject } from "@/hooks/loggedInObjectHook";
import { splitUpGames, useUserGames } from "@/hooks/userGamesHook";
import Checkbox from "@mui/material/Checkbox";

export default function Room() {
  const { roomId } = useParams();
  const { user, firebase } = useUser();
  const userGames = useUserGames();
  const [roomGames, setRoomGames] = useState<SomeGameData[] | undefined>();
  const [isEditing, setIsEditing] = useState(false);
  const uid = user?.uid;
  const { object: room, loading, error } = useLoggedInFirestoreObject(`rooms/${roomId}`, RoomData);

  // update roomGames
  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { collection, onSnapshot, query, where } = firebase.firestorePackage;
    const gamesCollection = collection(firebase.firestore, "games");
    const q = query(gamesCollection, where("inRoom", "==", roomId));
    return onSnapshot(q, (snapshot) => {
      const games: SomeGameData[] = [];
      snapshot.forEach((doc) => {
        games.push(SomeGameData.parse(doc.data()));
      });
      setRoomGames(games);
    });
  }, [firebase, roomId]);

  if (!roomId) {
    return <div>Missing roomId</div>;
  }
  if (!room) {
    return <div>Room not found</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const hostingGames = !uid || !userGames ? [] : splitUpGames(userGames, { uid }).hosting;
  const roomlessGames = hostingGames.filter((game) => game.inRoom === undefined);

  function addGameToRoom(gameId: string) {
    if (!firebase || !uid || !roomId) {
      return;
    }
    const { doc, collection, updateDoc } = firebase.firestorePackage;
    const gameDoc = doc(collection(firebase.firestore, "games"), gameId);
    updateDoc(gameDoc, { inRoom: roomId }).catch(alert);
  }

  function removeGameFromRoom(gameId: string) {
    if (!firebase) {
      return;
    }
    const { doc, collection, deleteField, updateDoc } = firebase.firestorePackage;
    const gameDoc = doc(collection(firebase.firestore, "games"), gameId);
    updateDoc(gameDoc, { inRoom: deleteField() }).catch(alert);
  }

  function setUseChips(useChips: boolean) {
    if (!firebase) {
      return;
    }
    const { doc, collection, updateDoc } = firebase.firestorePackage;
    const roomDoc = doc(collection(firebase.firestore, "rooms"), roomId);
    updateDoc(roomDoc, { useChips }).catch(alert);
  }

  return (
    <FullSizeCard>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <h1>{room.title}</h1>
          {uid === room.uid && (
            <IconButton onClick={() => setIsEditing(!isEditing)}>{isEditing ? <CheckIcon /> : <EditIcon />}</IconButton>
          )}
        </Stack>
        <h2>Games in this room</h2>
        {roomGames?.map((game, i) => (
          <Stack key={i} direction="row" spacing={2}>
            {isEditing && (
              <Button onClick={() => removeGameFromRoom(game.id)}>
                <RemoveCircleIcon />
              </Button>
            )}
            <GameCard key={i} game={game} />
          </Stack>
        ))}
        {isEditing && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox checked={room.useChips ?? false} onChange={(e) => setUseChips(e.target.checked)} />
            <Typography>Maintain chip balances in this room</Typography>
          </Stack>
        )}
        {!isEditing && (
          <Typography>
            {room.useChips ? "Maintaining chip balances in this room" : "No chips used in this room"}
          </Typography>
        )}
        {isEditing && roomlessGames.length > 0 && (
          <>
            <h2>Your games not in any room</h2>
            <Stack spacing={2}>
              {roomlessGames.map((game, i) => (
                <Stack key={i} direction="row" spacing={2}>
                  <Button onClick={() => addGameToRoom(game.id)}>
                    <AddCircleIcon />
                  </Button>
                  <GameCard key={i} game={game} />
                </Stack>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </FullSizeCard>
  );
}
