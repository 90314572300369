import Person from "@mui/icons-material/Person";

import { AvatarData } from "@sportsball/shared";
import React from "react";

export default function AvatarIcon({
  avatar,
  size,
  ...props
}: { avatar?: AvatarData; size: "large" | "medium" | "small" } & React.SVGProps<SVGSVGElement>) {
  if (!avatar) {
    return <Person fontSize={size} />;
  }
  let sizeNum: number;
  switch (size) {
    case "small":
      sizeNum = 24;
      break;
    case "medium":
      sizeNum = 40;
      break;
    case "large":
      sizeNum = 64;
      break;
  }

  const href = `/${avatar.set}.svg#${String(avatar.id)}`;
  return (
    <svg height={sizeNum} width={sizeNum} version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <use href={href}></use>
    </svg>
  );
}
