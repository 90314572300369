import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { AvatarSet, AvatarSetSizes, UserData } from "@sportsball/shared";

import AvatarIcon from "@/avatar/AvatarIcon";
import { useUser } from "@/context/useUser";

export default function UserPrefsDialog({ open, closeDialog }: { open: boolean; closeDialog: () => void }) {
  const { firebase, user } = useUser();
  const [newUserData, setNewUserData] = useState<UserData | undefined>();
  const [handleError, setHandleError] = useState("");
  const signedInUid = user && user?.type !== "anonymous" ? user.uid : undefined;
  const firstTimeSignin = user?.type === "signedInNoData";

  useEffect(() => {
    if (!firstTimeSignin || newUserData) {
      return;
    }
    const set: AvatarSet = "avatars-1";
    const id = Math.floor(Math.random() * AvatarSetSizes[set]);
    setNewUserData({
      publicId: { avatar: { set, id }, handle: "" },
      preferredInitials: "",
      searchHandle: "",
      email: "",
    });
  }, [newUserData, firstTimeSignin]);

  useEffect(() => {
    if (!signedInUid || !firebase) {
      return;
    }
    const { collection, doc, onSnapshot } = firebase.firestorePackage;
    const usersCollection = collection(firebase.firestore, "users");
    const userPrefsRef = doc(usersCollection, signedInUid);

    return onSnapshot(userPrefsRef, (snapshot) => {
      if (!snapshot.exists()) {
        setNewUserData(undefined);
        return;
      }
      const results = UserData.safeParse(snapshot.data());
      if (!results.success) {
        return;
      }

      setNewUserData(results.data);
    });
  }, [signedInUid, firebase]);

  function changeAvatar(amount: number) {
    if (!newUserData) {
      return;
    }
    const { avatar } = newUserData.publicId;
    const avatarSetSize = AvatarSetSizes[avatar.set] ?? 1;
    setNewUserData({
      ...newUserData,
      publicId: {
        ...newUserData.publicId,
        avatar: { ...avatar, id: (avatar.id + amount + avatarSetSize) % avatarSetSize },
      },
    });
  }

  const generateSearchHandle = (handle: string) => {
    return handle.toLowerCase().replace(/_/g, "");
  };

  const isHandleValid = (value: string): true | string => {
    if (!/^[a-zA-Z0-9_]+$/.test(value)) {
      return "Only letters, numbers, and underscores";
    }
    if (value.length > 15) {
      return "15 characters or fewer";
    }
    const searchHandle = generateSearchHandle(value);
    if (searchHandle.length < 3) {
      return "At least 3 characters";
    }
    return true;
  };

  const validateHandle = async (value: string): Promise<boolean> => {
    // First check basic validation
    const validationResult = isHandleValid(value);
    if (validationResult !== true) {
      setHandleError(validationResult);
      return false;
    }

    // Then check uniqueness
    if (firebase) {
      const { collection, query, where, getDocs } = firebase.firestorePackage;
      const usersCollection = collection(firebase.firestore, "users");
      const searchHandle = generateSearchHandle(value);

      // Don't check against the current user's handle
      const handleQuery = query(usersCollection, where("searchHandle", "==", searchHandle));

      const querySnapshot = await getDocs(handleQuery);
      const exists = querySnapshot.docs.some((doc) => doc.id !== signedInUid);

      if (exists) {
        setHandleError("This handle is already taken");
        return false;
      }
    }

    setHandleError("");
    return true;
  };

  const saveEnabled = newUserData && isHandleValid(newUserData.publicId.handle) === true;
  const handleSave = async () => {
    if (!saveEnabled || !firebase || user?.type === "anonymous") {
      console.error("Missing required user preferences");
      return;
    }

    // Validate handle uniqueness before saving
    const isValid = await validateHandle(newUserData.publicId.handle);
    if (!isValid) {
      return;
    }

    const { currentUser } = firebase.auth;
    if (!currentUser) {
      throw new Error("No current user");
    }

    try {
      const userDataToSave: UserData = {
        publicId: newUserData.publicId,
        searchHandle: generateSearchHandle(newUserData.publicId.handle),
        preferredInitials: newUserData.preferredInitials,
        email: currentUser.email || "",
      };
      const { collection, doc, setDoc, updateDoc } = firebase.firestorePackage;
      const usersCollection = collection(firebase.firestore, "users");
      if (user.type === "signedInNoData") {
        await setDoc(doc(usersCollection, signedInUid), userDataToSave);
      } else {
        await updateDoc(doc(usersCollection, signedInUid), userDataToSave);
      }
      closeDialog();
    } catch (error) {
      console.error("Failed to save user preferences:", error);
    }
  };

  return (
    <Dialog open={open} onClose={!firstTimeSignin ? closeDialog : undefined} disableEscapeKeyDown={firstTimeSignin}>
      <DialogTitle variant="h5">Your Settings</DialogTitle>
      <DialogContent>
        <Typography fontSize={12}>&nbsp;&nbsp;&nbsp;&nbsp;Your avatar</Typography>
        {newUserData && (
          <Stack spacing={2} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={() => changeAvatar(-1)}>
                <KeyboardArrowLeftIcon fontSize="large" />
              </IconButton>
              <AvatarIcon avatar={newUserData.publicId.avatar} size="large" />
              <IconButton onClick={() => changeAvatar(+1)}>
                <KeyboardArrowRightIcon fontSize="large" />
              </IconButton>
            </Stack>
            <TextField
              label="Handle"
              value={newUserData.publicId.handle}
              onChange={(e) => {
                const value = e.target.value;
                setNewUserData({ ...newUserData, publicId: { ...newUserData.publicId, handle: value } });
                void validateHandle(value);
              }}
              error={!!handleError}
              helperText={handleError}
            ></TextField>
            <TextField
              label="Preferred Initials"
              value={newUserData.preferredInitials}
              onChange={(e) => setNewUserData({ ...newUserData, preferredInitials: e.target.value })}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {!firstTimeSignin && <Button onClick={closeDialog}>Cancel</Button>}
        <Button onClick={() => void handleSave()} disabled={!saveEnabled}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
