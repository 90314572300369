import { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import { RoomData, SomeGameData } from "@sportsball/shared";

import FullSizeCard from "@/components/FullSizeCard";
import GameCard from "@/components/GameCard";

import { LazyFirebase } from "@/context/useFirebasePreUser";
import { useUser } from "@/context/useUser";
import { splitUpGames, useUserGames } from "@/hooks/userGamesHook";
import { RoomLists, useUserRooms } from "@/hooks/userRoomsHook";

export function searchByCode(
  code: string | undefined,
  setGame: (game: SomeGameData | undefined) => void,
  firebase: LazyFirebase | undefined
) {
  if (!code || !firebase) {
    setGame(undefined);
    return;
  }
  const { collection, onSnapshot, query, where } = firebase.firestorePackage;
  const gamesCollection = collection(firebase.firestore, "games");
  const q = query(gamesCollection, where("code", "==", code.toUpperCase()));
  return onSnapshot(q, (snapshot) => {
    if (snapshot.empty) {
      setGame(undefined);
    } else {
      snapshot.forEach((gameSnapshot) => {
        const result = SomeGameData.safeParse(gameSnapshot.data());
        if (!result.success) {
          setGame(undefined);
          return;
        }
        setGame(result.data);
      });
    }
  });
}

function HeroSection() {
  return (
    <FullSizeCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          py: 4,
          px: 2,
        }}
      >
        <Box sx={{ mt: 2, mb: 4, textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              mb: 3,
            }}
          >
            MLB Baseball Squares
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              mb: 4,
              fontSize: { xs: "1.25rem", md: "1.5rem" },
              color: "text.secondary",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            The classic Super Bowl party game pasttime comes to baseball! Opening Spring Training 2025!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 4,
              color: "text.secondary",
              fontStyle: "italic",
            }}
          >
            Brought to you by Sportsball
          </Typography>
        </Box>
        <Link
          href="/games/new/baseball-squares"
          sx={{
            textDecoration: "none",
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{
              py: 2,
              px: 4,
              fontSize: "1.25rem",
            }}
          >
            Create Your MLB Baseball Squares Game
          </Button>
        </Link>
      </Box>
    </FullSizeCard>
  );
}

function RoomCard({ room, games }: { room: RoomData; games: SomeGameData[] }) {
  const roomGames = games.filter((game) => game.inRoom === room.roomId);

  return (
    <FullSizeCard>
      <Box sx={{ border: 1, borderColor: "divider", borderRadius: 1, p: 2 }}>
        <Link href={`/rooms/${room.roomId}`}>
          <Typography variant="h4" component="h2" gutterBottom>
            {room.title}
          </Typography>
        </Link>
        <Stack spacing={2}>
          {roomGames.map((game, i) => (
            <GameCard key={i} game={game} />
          ))}
        </Stack>
      </Box>
    </FullSizeCard>
  );
}

function ActiveRoomsSection({
  rooms: { hostingRooms, memberOfRooms },
  games,
}: {
  rooms: RoomLists;
  games: SomeGameData[];
}) {
  return (
    <FullSizeCard>
      <Typography variant="h4" component="h2" gutterBottom>
        Your Rooms
      </Typography>
      <Stack spacing={2}>
        {hostingRooms.map((room, i) => (
          <RoomCard key={i} room={room} games={games} />
        ))}
        {memberOfRooms.map((room, i) => (
          <RoomCard key={i} room={room} games={games} />
        ))}
      </Stack>
    </FullSizeCard>
  );
}

function ActiveGamesSection({ games, uid }: { games: SomeGameData[]; uid: string }) {
  const roomlessGames = games.filter((game) => !game.inRoom);
  const { hosting, enteredNotHosting } = splitUpGames(roomlessGames, { uid });

  if (hosting.length === 0 && enteredNotHosting.length === 0) {
    return null;
  }

  return (
    <FullSizeCard>
      <Stack spacing={4}>
        {hosting.length > 0 && (
          <Box>
            <Stack spacing={2}>
              {hosting.map((game, i) => (
                <GameCard key={i} game={game} />
              ))}
            </Stack>
          </Box>
        )}

        {enteredNotHosting.length > 0 && (
          <Box>
            <Stack spacing={2}>
              {enteredNotHosting.map((game, i) => (
                <GameCard key={i} game={game} />
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    </FullSizeCard>
  );
}

interface JoinGameSectionProps {
  setSearchGameCode: (code: string) => void;
  searchGame: SomeGameData | undefined;
}

function JoinGameSection({ setSearchGameCode, searchGame }: JoinGameSectionProps) {
  return (
    <FullSizeCard>
      <Typography variant="h4" component="h2" gutterBottom>
        Join a squares game by code
      </Typography>
      <Stack spacing={3}>
        <Typography variant="body1" component="p">
          Got an invite code? Enter the game code below to join the fun! Each pool has a unique code shared by the host.
        </Typography>

        <TextField
          fullWidth
          id="searchGameCode"
          label="Enter Pool Code"
          placeholder="Example: ABC123"
          variant="outlined"
          sx={{ maxWidth: 300 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { textTransform: "uppercase" },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
            }
          }}
          onChange={(e) => setSearchGameCode(e.target.value.toUpperCase())}
        />

        {searchGame && (
          <Box sx={{ mt: 2 }}>
            <GameCard game={searchGame} />
          </Box>
        )}
      </Stack>
    </FullSizeCard>
  );
}

export default function Home() {
  const { firebase, user } = useUser();
  const [searchGameCode, setSearchGameCode] = useState<string | undefined>();
  const [searchGame, setSearchGame] = useState<SomeGameData | undefined>();

  useEffect(() => searchByCode(searchGameCode, setSearchGame, firebase), [searchGameCode, firebase]);

  const uid = user?.uid;
  const games = useUserGames();
  const rooms = useUserRooms();

  return (
    <Stack spacing={0.5}>
      <HeroSection />
      {rooms && games && <ActiveRoomsSection rooms={rooms} games={games} />}
      {uid && games && <ActiveGamesSection games={games} uid={uid} />}
      <JoinGameSection setSearchGameCode={setSearchGameCode} searchGame={searchGame} />
    </Stack>
  );
}
