import { origin } from "./main";

import { ProdFirebaseConfig, StageFirebaseConfig } from "@sportsball/shared";

import type { FirebaseError } from "firebase/app";
import { LazyFirebase } from "./context/useFirebasePreUser";

/**
 * Settings to allow for dev backend configuration. These
 * settings are safe to check into git in any position.
 */
const _devFirebaseConfig = StageFirebaseConfig;
const _devUsingEmulator = true;

const _origin = origin();
export const devUsingEmulator = true;
export const firebaseConfig = {
  prod: ProdFirebaseConfig,
  stage: StageFirebaseConfig,
  localhost: _devFirebaseConfig,
}[_origin];

if (_origin === "localhost" && _devUsingEmulator) {
  const x = globalThis as unknown as {
    __FIREBASE_DEFAULTS__: { emulatorHosts: { auth: string; database: string; firestore: string; functions: string } };
  };
  x.__FIREBASE_DEFAULTS__ = {
    emulatorHosts: {
      auth: "localhost:9099",
      database: "localhost:9000",
      firestore: "localhost:8080",
      functions: "localhost:5001",
    },
  };
}

export function isFirebaseError(error: unknown, firebase: LazyFirebase): error is FirebaseError {
  return error instanceof firebase.appPackage.FirebaseError;
}

export function isAuthError(error: unknown, firebase: LazyFirebase): error is FirebaseError {
  return isFirebaseError(error, firebase) && error.code.startsWith("auth/");
}

// we might try flipping to localhost:9000 for local dev but that's not working well
// so we just always access stage for local dev
export function getDatabaseUrl() {
  return firebaseConfig.databaseURL;
}
