import { useEffect, useState } from "react";

import { RoomData } from "@sportsball/shared";

import { useUser } from "@/context/useUser";

export interface RoomLists {
  hostingRooms: RoomData[];
  memberOfRooms: RoomData[];
}

export function useUserRooms() {
  const { firebase, user } = useUser();
  const [rooms, setRooms] = useState<RoomLists | undefined>();
  const uid = user?.uid;

  useEffect(() => {
    if (!firebase || !uid) {
      return;
    }
    const { collection, onSnapshot, query, where } = firebase.firestorePackage;
    const roomsCollection = collection(firebase.firestore, "rooms");
    const q = query(roomsCollection, where("userRefs", "array-contains", uid));
    return onSnapshot(q, (snapshot) => {
      const roomLists: RoomLists = { hostingRooms: [], memberOfRooms: [] };
      snapshot.forEach((doc) => {
        const room = RoomData.parse(doc.data());
        if (room.uid === uid) {
          roomLists.hostingRooms.push(room);
        } else {
          roomLists.memberOfRooms.push(room);
        }
      });
      setRooms(roomLists);
    });
  }, [firebase, uid]);

  return rooms;
}
