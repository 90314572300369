import { useEffect, useState } from "react";

import { SomeGameData } from "@sportsball/shared";

import { useUser } from "@/context/useUser";

export function useUserGames() {
  const { firebase, user } = useUser();
  const [games, setGames] = useState<SomeGameData[] | undefined>();
  const uid = user?.uid;

  useEffect(() => {
    if (!firebase || !uid) {
      return;
    }
    const { collection, onSnapshot, query, where } = firebase.firestorePackage;
    const gamesCollection = collection(firebase.firestore, "games");
    const q = query(gamesCollection, where("userRefs", "array-contains", uid));
    return onSnapshot(q, (snapshot) => {
      const games: SomeGameData[] = [];
      snapshot.forEach((doc) => {
        games.push(SomeGameData.parse(doc.data()));
      });
      setGames(games);
    });
  }, [firebase, uid]);

  return games;
}

export function splitUpGames(games: SomeGameData[] | undefined, { uid }: { uid: string }) {
  const hosting: SomeGameData[] = [];
  const enteredNotHosting: SomeGameData[] = [];
  if (games) {
    for (const game of games) {
      if (game.uid === uid) {
        hosting.push(game);
      } else {
        enteredNotHosting.push(game);
      }
    }
    hosting.sort((a, b) => b.startTimestamp - a.startTimestamp);
    enteredNotHosting.sort((a, b) => b.startTimestamp - a.startTimestamp);
  }

  return { hosting, enteredNotHosting };
}
