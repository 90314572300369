import { useContext } from "react";
import { createContext } from "react";

import { LazyFirebase } from "./useFirebasePreUser";
import { UserData } from "@sportsball/shared";

export interface AnonymousUser {
  type: "anonymous";
  uid: string;
}

export interface SignedInUserWithoutData {
  type: "signedInNoData";
  uid: string;
}

export interface SignedInUser extends UserData {
  type: "signedIn";
  uid: string;
}

export type User = AnonymousUser | SignedInUserWithoutData | SignedInUser;

// if firebase is undefined, user will be undefined.
type UserContextType =
  | {
      firebase: undefined;
      user: undefined;
      setOtherTakeoverCode: (params: { anonUid: string; takeoverCode: string; email: string }) => void;
      signOut: () => Promise<void>;
    }
  | {
      firebase: LazyFirebase;
      user: User;
      setOtherTakeoverCode: (params: { anonUid: string; takeoverCode: string; email: string }) => void;
      signOut: () => Promise<void>;
    };

export const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
}
