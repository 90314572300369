import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RoomData, SomeGameData } from "@sportsball/shared";

import ShortUniqueId from "short-unique-id";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

import GridOnIcon from "@mui/icons-material/GridOn";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Person from "@mui/icons-material/Person";
import PersonOff from "@mui/icons-material/PersonOff";
import SearchIcon from "@mui/icons-material/Search";
import { FaDiscord } from "react-icons/fa";

import AboutBox from "@/components/AboutBox";
import { searchByCode } from "@/pages/Home";
import { useUser } from "@/context/useUser";

export default function AppDrawer({
  open,
  closeDrawer,
  serviceVersion,
  showUserSignIn,
}: {
  open: boolean;
  closeDrawer: () => void;
  serviceVersion: string | undefined;
  showUserSignIn: () => void;
}) {
  const navigate = useNavigate();
  const { firebase, user, signOut } = useUser();
  const [showSearchByCode, setShowSearchByCode] = useState(false);
  const [searchGameCode, setSearchGameCode] = useState("");
  const [searchGame, setSearchGame] = useState<SomeGameData | undefined>();
  const [showAboutBox, setShowAboutBox] = useState(false);

  useEffect(() => {
    if (searchGame) {
      closeDrawer();
      navigate(`/games/${searchGame.id}`);
      return;
    }
    if (!showSearchByCode || !searchGameCode) {
      return;
    }
    return searchByCode(searchGameCode, setSearchGame, firebase);
  }, [closeDrawer, navigate, searchGame, showSearchByCode, searchGameCode, firebase]);

  function closeDrawerAnd(fn: () => void) {
    closeDrawer();
    fn();
  }

  function searchByCodeDialog() {
    return (
      <Dialog
        open={showSearchByCode}
        onClose={() => setShowSearchByCode(false)}
        aria-labelledby="search-by-code-dialog-title"
      >
        <DialogTitle id="search-by-code-dialog-title">Search by code</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the code of the game you want to join</DialogContentText>
          <TextField
            margin="dense"
            id="code"
            name="code"
            label="Game code"
            fullWidth
            variant="standard"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
              }
            }}
            onChange={(event) => setSearchGameCode(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDrawerAnd(() => setShowSearchByCode(false))}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const newRoom = async () => {
    if (!firebase || user?.type !== "signedIn") {
      return;
    }
    const { collection, doc, setDoc } = firebase.firestorePackage;
    const shortUniqueId = new ShortUniqueId();
    const roomId = shortUniqueId.rnd();

    const newRoom: RoomData = {
      uid: user.uid,
      roomId,
      title: `${user.publicId.handle}'s room`,
      userRefs: [user.uid],
      members: { [user.uid]: { userPublicId: user.publicId } },
    };
    try {
      await setDoc(doc(collection(firebase.firestore, "rooms"), roomId), newRoom);
      navigate(`/rooms/${roomId}`);
    } catch (error) {
      console.error("Error creating game room", error);
    }
  };

  return (
    <Drawer open={open} onClose={closeDrawer}>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box>
          <List>
            {/* Home */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => closeDrawerAnd(() => navigate("/"))}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>

            <Divider />

            {/* Search */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => setShowSearchByCode(true)}>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Find game by code" />
              </ListItemButton>
            </ListItem>

            <Divider />

            {/* Create Games */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => closeDrawerAnd(() => navigate("/games/new/baseball-squares"))}>
                <ListItemIcon>
                  <GridOnIcon />
                </ListItemIcon>
                <ListItemText primary="New MLB squares game" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => closeDrawerAnd(() => void newRoom())} disabled={user?.type !== "signedIn"}>
                <ListItemIcon>
                  <MeetingRoomIcon />
                </ListItemIcon>
                <ListItemText primary="New game room" />
              </ListItemButton>
            </ListItem>

            <Divider />

            {/* About */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => setShowAboutBox(true)}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
          </List>

          {/* Auth Section */}
          {<Divider />}
          {user?.type !== "signedIn" && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => closeDrawerAnd(showUserSignIn)}>
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="Sign in" />
              </ListItemButton>
            </ListItem>
          )}
          {user?.type === "signedIn" && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => closeDrawerAnd(() => void signOut())}>
                <ListItemIcon>
                  <PersonOff />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItemButton>
            </ListItem>
          )}
        </Box>

        {/* Discord Support Link */}
        <Box sx={{ marginTop: "auto", borderTop: 1, borderColor: "divider" }}>
          <List>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://discord.gg/Yg9bwsXMkB" target="_blank" rel="noopener">
                <ListItemIcon>
                  <FaDiscord style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText primary="Join Discord Community" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>

      {/* Dialogs */}
      <AboutBox
        firebase={firebase}
        open={showAboutBox}
        serviceVersion={serviceVersion}
        closeDialog={() => setShowAboutBox(false)}
      />
      {searchByCodeDialog()}
    </Drawer>
  );
}
