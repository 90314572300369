import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ScoreProps } from "@/style";

import { MultiTargetScore, RowCol } from "@sportsball/shared";
import { LightGridGuideColor } from "./SquaresBoardColors";

export default function TargetScore({
  score,
  side,
  rowOrCol,
  visibility,
  activeCell,
  size,
}: {
  score: number | MultiTargetScore | undefined;
  side: "Home" | "Away";
  rowOrCol: number;
  visibility?: "visible" | "hidden";
  activeCell?: RowCol;
  size: number;
}) {
  let textcolor: string | undefined;
  let bgcolor: string | undefined;
  if (score && activeCell) {
    if (side === "Away" ? activeCell.row === rowOrCol : activeCell.col === rowOrCol) {
      bgcolor = LightGridGuideColor;
    }
  }
  if (size === 100) {
    return (
      <Grid size={1} paddingY={1} textAlign="center" borderBottom={2} borderRight={2} bgcolor={bgcolor}>
        <Typography {...ScoreProps} fontWeight="bold" color={textcolor} visibility={visibility}>
          {score === undefined || typeof score !== "number" ? "?" : score}
        </Typography>
      </Grid>
    );
  }
  let one = "?";
  let two = "?";
  if (score) {
    if (typeof score !== "object") {
      throw new Error("Not implemented");
    }
    one = score.multi[0].toString();
    two = score.multi[1].toString();
  }
  return (
    <Grid size={1} paddingY={1} textAlign="center" borderBottom={2} borderRight={2} bgcolor={bgcolor}>
      <Stack direction="row" justifyContent="center" visibility={visibility}>
        <Box sx={{ position: "relative", right: "2px", bottom: "8px" }}>
          <Typography {...ScoreProps} fontWeight="bold" color={textcolor}>
            {one}
          </Typography>
        </Box>
        <Box>
          <Typography {...ScoreProps} color={textcolor} sx={{ transform: "rotate(45deg)" }}>
            |
          </Typography>
        </Box>
        <Box sx={{ position: "relative", left: "2px", top: "8px" }}>
          <Typography {...ScoreProps} color={textcolor} fontWeight="bold">
            {two}
          </Typography>
        </Box>
      </Stack>
    </Grid>
  );
}
