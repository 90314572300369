import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import {
  BasicEntryData,
  BasicEntryDataWithType,
  PickemGameData,
  RoomData,
  SomeGameData,
  SquaresGameData,
} from "@sportsball/shared";

import { processError } from "@/data/webUtils";
import { useLoggedInFirestoreObject } from "@/hooks/loggedInObjectHook";
import { useUserGames } from "@/hooks/userGamesHook";
import { useUser } from "@/context/useUser";
import deepEqual from "deep-equal";

export interface GameRoomData {
  title: string;
  chipBalance: number | undefined;
}
export interface SquaresGameContainerProps {
  type: "Squares";
  game: SquaresGameData;
  gameId: string;
  gameOn: boolean;
  roomData: GameRoomData | undefined;
  pregameEntries: Partial<Record<string, BasicEntryData>> | undefined;
}

export interface PickemGameContainerProps {
  type: "Pickem";
  game: PickemGameData;
  gameId: string;
  gameOn: boolean;
  roomData: GameRoomData | undefined;
  pregameEntries: Partial<Record<string, BasicEntryData>> | undefined;
}

export type GameContainerProps = SquaresGameContainerProps | PickemGameContainerProps;

export default function GameContainer({ gameId: gameIdFromArgs }: { gameId?: string }) {
  const { firebase, user } = useUser();
  const { gameId: gameIdFromParams } = useParams();
  const gameId = gameIdFromArgs || gameIdFromParams;
  if (!gameId) {
    throw new Error("Missing gameId");
  }
  const games = useUserGames();
  const initialData = games?.find((game) => game.id === gameId);
  const { object: game, loading, error } = useLoggedInFirestoreObject(`games/${gameId}`, SomeGameData, initialData);
  const [pregameEntries, setPregameEntries] = useState<Record<string, BasicEntryData>>();
  const startTimestamp = game?.startTimestamp;
  const [gameOn, setGameOn] = useState(startTimestamp ? Date.now() >= startTimestamp : false);
  const [roomData, setRoomData] = useState<GameRoomData | undefined>();

  const isPregame = !game?.lockedEntries;
  const uid = user?.uid;

  useEffect(() => {
    if (!firebase || !game?.inRoom || !uid) {
      return;
    }
    const { doc, onSnapshot } = firebase.firestorePackage;
    const roomDoc = doc(firebase.firestore, `rooms/${game.inRoom}`);
    return onSnapshot(roomDoc, (snapshot) => {
      if (snapshot.exists()) {
        const roomData = RoomData.parse(snapshot.data());
        const memberData = roomData.members[uid];
        if (memberData) {
          const newRoomGameData: GameRoomData = {
            title: roomData.title ?? "",
            chipBalance: memberData.chipCount,
          };
          if (!deepEqual(newRoomGameData, roomData)) {
            setRoomData(newRoomGameData);
          }
        }
      } else {
        setRoomData(undefined);
      }
    });
  }, [firebase, game?.inRoom, uid]);

  // collect game pre-game entries
  useEffect(() => {
    if (!firebase) {
      return;
    }
    setPregameEntries(undefined);
    if (!isPregame) {
      return;
    }
    const { collection, onSnapshot } = firebase.firestorePackage;
    const pregameEntriesCollection = collection(firebase.firestore, `games/${gameId}/pregame-entries`);
    return onSnapshot(pregameEntriesCollection, (snapshot) => {
      const pregameEntries: Record<string, BasicEntryData> = {};
      snapshot.forEach((doc) => {
        pregameEntries[doc.id] = BasicEntryDataWithType.parse(doc.data());
      });
      setPregameEntries(pregameEntries);
    });
  }, [firebase, gameId, isPregame]);

  // Every second we check to see if gameOn needs flipping
  useEffect(() => {
    if (startTimestamp === undefined || gameOn) {
      return;
    }
    const interval = setInterval(() => {
      setGameOn(Date.now() >= startTimestamp);
    }, 1000);
    return () => clearInterval(interval);
  }, [startTimestamp, gameOn]);

  if (error || loading || !game) {
    return processError({ loading, error });
  }

  return <Outlet context={{ type: game.type, game, gameId, gameOn, pregameEntries, roomData }} />;
}
