import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";

import { format } from "date-fns";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

import { FullPickemEntryData, isStartedPickemGame, PickemEntryPrivateData, Side } from "@sportsball/shared";
import { isPickemGame } from "@sportsball/shared";

import ConfirmDialog from "@/components/ConfirmDialog";
import EntryLine from "@/components/EntryLine";
import FullSizeCard from "@/components/FullSizeCard";
import LeftRightButtons from "@/components/LeftRightButtons";
import PickemSheet from "@/components/PickemSheet";
import { GameContainerProps } from "@/components/GameContainer";

import { useUser } from "@/context/useUser";
import { deleteEntry } from "@/cloudFunctions";

export default function ShowEntry() {
  const navigate = useNavigate();
  const { type, game, gameId, gameOn, pregameEntries } = useOutletContext<GameContainerProps>();
  const { firebase, user } = useUser();
  const [entryPrivate, setEntryPrivate] = useState<PickemEntryPrivateData | undefined | null>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  if (!isPickemGame(game) || type !== "Pickem") {
    throw new Error("Game is not a pickem game");
  }
  const { entryId } = useParams();
  if (!entryId) {
    throw new Error("No arg");
  }
  const uid = user?.uid;
  const isStarted = isStartedPickemGame(game);
  const myPregameEntry = pregameEntries?.[entryId];

  useEffect(() => {
    if (!firebase || isStarted || !myPregameEntry || myPregameEntry.uid !== uid) {
      return;
    }

    const { doc, onSnapshot } = firebase.firestorePackage;
    const privateEntryRef = doc(firebase.firestore, `games/${gameId}/pregame-entries-private/${entryId}`);
    return onSnapshot(privateEntryRef, (doc) => {
      if (!doc.exists()) {
        setEntryPrivate(null);
      } else {
        setEntryPrivate(PickemEntryPrivateData.parse(doc.data()));
      }
    });
  }, [firebase, gameId, entryId, myPregameEntry, isStarted, uid]);

  let entry: FullPickemEntryData | undefined;
  if (isStarted) {
    entry = game.lockedEntries?.[entryId];
  } else if (myPregameEntry && myPregameEntry.uid !== uid) {
    return <div>Permission denied</div>;
  } else if (!pregameEntries || entryPrivate === undefined) {
    return <CircularProgress />;
  } else if (!myPregameEntry) {
    entry = undefined;
  } else {
    entry = {
      ...myPregameEntry,
      ...entryPrivate,
    };
  }

  if (!entry) {
    return <div>Entry not found</div>;
  }

  const actualAnswers = game.actualAnswers ?? {};
  let navEntryIds: string[] | undefined;
  if (isStarted) {
    navEntryIds = Object.keys(game.lockedEntries ?? {});
  } else if (pregameEntries) {
    navEntryIds = Object.entries(pregameEntries)
      .filter(([, entry]) => entry?.uid === uid)
      .map(([entryId]) => entryId);
  }
  const entryPaths = navEntryIds?.map((entryId) => `/games/${gameId}/entries/${entryId}`);

  let gameState: "pregame" | "midgame" | "postgame" | undefined;
  const { startTimestamp } = game;
  if (!startTimestamp) {
    gameState = "pregame"; // no start timestamp means no games
  } else if (Date.now() < startTimestamp) {
    gameState = "pregame";
  } else if (actualAnswers && Object.keys(actualAnswers).length === Object.keys(game.questions).length) {
    gameState = "postgame";
  } else {
    gameState = "midgame";
  }
  const myEntry = entry.uid === uid;
  const editableEntry = myEntry && gameState === "pregame";

  const makePick =
    !editableEntry || !firebase
      ? undefined
      : ({ questionNumber, pick }: { questionNumber: number; pick: Side }) => {
          const { doc, updateDoc } = firebase.firestorePackage;
          const pregameEntryRef = doc(firebase.firestore, `games/${gameId}/pregame-entries-private/${entryId}`);
          updateDoc(pregameEntryRef, { [`selectedAnswers.${questionNumber}`]: pick }).catch(alert);
        };

  const setTiebreakerPoints =
    !editableEntry || !firebase
      ? undefined
      : (points: number) => {
          const { doc, updateDoc } = firebase.firestorePackage;
          const pregameEntryRef = doc(firebase.firestore, `games/${gameId}/pregame-entries-private/${entryId}`);
          updateDoc(pregameEntryRef, { tiebreakerPoints: points }).catch(alert);
        };

  function performDelete() {
    if (!firebase || !entryId) {
      return;
    }
    deleteEntry(firebase, { gameId, entryId })
      .then(() => {
        setShowDeleteDialog(false);
        navigate(`/games/${gameId}`);
      })
      .catch(alert);
  }

  const localeDate = !startTimestamp ? "-" : format(startTimestamp, "PP").split(",")[0];
  const localeTime = !startTimestamp ? "-" : format(startTimestamp, "p");
  const userPublicId = game.users?.[entry.uid];
  return (
    <>
      <FullSizeCard>
        {game.name && <Link to={`/games/${gameId}`}>Back to game {game.name}</Link>}
        <Stack direction="row">
          <EntryLine entryId={entryId} entry={entry} gameId={gameId} gameOn={gameOn} userPublicId={userPublicId} />
          {entryPaths && <LeftRightButtons paths={entryPaths} />}
        </Stack>
      </FullSizeCard>
      <FullSizeCard>
        <h2>Picks</h2>
        {editableEntry && (
          <p>
            Feel free to change your picks below before the first game starts at {localeTime} on {localeDate}.
          </p>
        )}
        {myEntry && gameState === "midgame" && <p>The games have begun! Good luck with your picks!</p>}
        {myEntry && gameState === "postgame" && <p>Game over.</p>}
        <Box alignItems="center">
          <PickemSheet
            game={game}
            picks={entry.selectedAnswers}
            results={game.actualAnswers}
            makePick={makePick}
            tiebreakerPoints={entry.tiebreakerPoints}
            setTiebreakerPoints={setTiebreakerPoints}
          />
        </Box>
        <Stack padding={4} spacing={2} direction="row" justifyContent="center">
          <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate(`/games/${gameId}`)}>
            Back to game
          </Button>
          {editableEntry && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setShowDeleteDialog(true)}
            >
              DELETE ENTRY
            </Button>
          )}
        </Stack>
      </FullSizeCard>
      <ConfirmDialog
        prompt="Are you sure you want to delete your pick'em entry?"
        confirmText="Delete"
        cancelText="Cancel"
        open={showDeleteDialog}
        confirmFn={performDelete}
        closeDialog={() => setShowDeleteDialog(false)}
      />
    </>
  );
}
