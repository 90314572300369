import { useFirebasePreUser } from "@/context/useFirebasePreUser";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { ScheduledBaseballGame } from "@sportsball/shared";
import { useEffect, useState } from "react";
import { z } from "zod";

export default function MlbGamePicker({
  selectedGame,
  setSelectedGame,
}: {
  selectedGame?: ScheduledBaseballGame;
  setSelectedGame: (game: ScheduledBaseballGame) => void;
}) {
  const firebase = useFirebasePreUser();
  const [baseballSchedule, setBaseballSchedule] = useState<ScheduledBaseballGame[] | undefined>();

  function setBaseballGameId(id: string) {
    const newGame = baseballSchedule?.find((game) => game.id === id);
    if (newGame) {
      setSelectedGame(newGame);
    }
  }

  useEffect(() => {
    if (!firebase) {
      return;
    }
    const { onValue, ref } = firebase.databasePackage;
    const baseballGamesRef = ref(firebase.database, "games/baseball/MLB");
    return onValue(baseballGamesRef, (snapshot) => {
      if (!snapshot.exists()) {
        return;
      }

      const baseballScheduleData = z.record(z.string(), z.array(ScheduledBaseballGame)).parse(snapshot.val());
      const newBaseballSchedule = Object.values(baseballScheduleData)
        .flat()
        .sort((a, b) => a.startTimestamp - b.startTimestamp);
      setBaseballSchedule(Object.values(newBaseballSchedule).flat());

      if (!selectedGame) {
        setSelectedGame(Object.values(newBaseballSchedule).flat()[0]);
      }
    });
  }, [firebase, selectedGame, setSelectedGame]);

  return !baseballSchedule || Object.keys(baseballSchedule).length === 0 ? null : (
    <TextField
      select
      fullWidth
      id="game"
      label={`MLB Game`}
      value={selectedGame?.id ?? ""}
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 400,
          },
        },
      }}
      onChange={(e) => setBaseballGameId(e.target.value)}
    >
      {baseballSchedule.map((game) => {
        return (
          <MenuItem key={game.id} value={game.id}>
            {game.teams.away.abbreviation} @ {game.teams.home.abbreviation}
            {" - "}
            {new Date(game.date).toLocaleDateString(undefined, { month: "short", day: "numeric" })}{" "}
            {new Date(game.date).toLocaleTimeString(undefined, { hour: "numeric", minute: "2-digit" })}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
