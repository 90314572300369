import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import { CreatePickemGameParameters, CreateSquaresGameParameters, getGamePath } from "@sportsball/shared";

import { useUser } from "@/context/useUser";
import SignInDialog from "@/components/SignInDialog";
import FullSizeCard from "@/components/FullSizeCard";

import { createGame, wakeUpFunctions } from "@/cloudFunctions";
import { IncomingCreateGameMessage, sendNewGameMessage } from "@/flutter/messageHandlers";
import { useUserRooms } from "@/hooks/userRoomsHook";
import NewSquaresGame, { NewSquaresGameData } from "./squares/NewSquaresGame";
import NewPickemGame, { NewPickemGameData } from "./NewPickemGame";
import deepEqual from "deep-equal";

export type PartialCreateGameParameters =
  | Omit<CreatePickemGameParameters, "id" | "name" | "description">
  | Omit<CreateSquaresGameParameters, "id" | "name" | "description">;

export type GetCreateGameData = () => PartialCreateGameParameters;

export interface GameTypeStrings {
  gameTypeDescription: string;
  longName: string;
}

function _isInWebview() {
  return window.location.pathname.startsWith("/webview");
}

export default function NewGame({
  gameType,
  sport,
}: {
  gameType: "Pickem" | "Squares";
  sport: "Football" | "Baseball";
}) {
  const navigate = useNavigate();
  const { firebase, user } = useUser();
  const [signInDialogOpen, setSignInDialogOpen] = useState(false);
  const [gameTypeStrings, setGameTypeStrings] = useState<GameTypeStrings | undefined>();

  const [gameName, setGameName] = useState<string | undefined>();
  const [gameDescription, setGameDescription] = useState<string>("");

  const [inRoom, setInRoom] = useState<string | undefined>();
  const [chipsPerEntry, setChipsPerEntry] = useState<number | undefined>();

  const [newGameData, _setNewGameData] = useState<NewSquaresGameData | NewPickemGameData | undefined>();

  const [isCreating, setIsCreating] = useState(false);
  const [calledWakeUpFunctions, setCalledWakeUpFunctions] = useState(false);

  const rooms = useUserRooms();
  const room = rooms?.hostingRooms?.find((room) => room.roomId === inRoom);
  const { longName, gameTypeDescription } = gameTypeStrings ?? {};

  const setNewGameData = useCallback(
    (withNewGameData: NewSquaresGameData | NewPickemGameData | undefined) => {
      if (!deepEqual(newGameData, withNewGameData)) {
        _setNewGameData(withNewGameData);
      }
    },
    [newGameData]
  );

  useEffect(() => {
    if (!room || !room.useChips || chipsPerEntry !== undefined) {
      return;
    }
    setChipsPerEntry(1);
  }, [room, chipsPerEntry]);

  useEffect(() => {
    if (!firebase || calledWakeUpFunctions) {
      return;
    }
    wakeUpFunctions(firebase);
    // anticipating the createGame call we warm up the bottleneck function
    setCalledWakeUpFunctions(true);
  }, [firebase, calledWakeUpFunctions]);

  useEffect(() => {
    window.receiveNewGameMessageImpl = (message: IncomingCreateGameMessage) => {
      console.log("Received message", message);
      if (message.action === "createGame") {
        console.log("Creating game");
        const submitButton = document.querySelector('button[type="submit"]');
        if (submitButton) {
          console.log("Clicking submit button");
          (submitButton as HTMLButtonElement).click();
        }
        return;
      }
    };

    return () => {
      window.receiveNewGameMessageImpl = undefined;
    };
  }, []);

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    if (!user || !firebase || !newGameData || !longName) {
      sendNewGameMessage({
        status: "error",
        message: "Not ready to create game",
      });
      throw new Error("Cannot submit form");
    }
    event.preventDefault();
    setIsCreating(true);

    const createGameParameters = {
      name: gameName ?? longName,
      description: gameDescription,
      ...newGameData,
    };
    // we don't want a null in the createGameParameters so we assign separately
    if (inRoom) {
      createGameParameters.inRoom = inRoom;
    }
    if (chipsPerEntry !== undefined) {
      createGameParameters.chipsPerEntry = chipsPerEntry;
    }

    createGame(firebase, createGameParameters)
      .then((gameId) => {
        sendNewGameMessage({
          status: "success",
          gameId: gameId,
        });
        if (!_isInWebview()) {
          navigate(getGamePath({ id: gameId }));
        }
      })
      .catch((e) => {
        console.error("Error creating game", e);
        sendNewGameMessage({
          status: "error",
          message: e instanceof Error ? e.message : String(e),
        });
        alert(e);
        setIsCreating(false);
      });
  }

  const isSignedIn = user?.type === "signedIn";

  return (
    <>
      <Helmet>
        <title>{`Create a new ${longName} pool | Sportsball`}</title>
        <meta
          name="description"
          content={`Sportsball is a place to play NFL and NCAA pick'em and squares pools with friends online. Here you can quickly start your own ${longName} pool and invite your friends to play.`}
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        <FullSizeCard>
          <h1>{`Create a new ${longName} pool`}</h1>
          <p>{gameTypeDescription}</p>
          <p>Name your game.</p>
          <TextField
            id="game-name"
            label="Game Name"
            variant="outlined"
            value={gameName ?? longName}
            onChange={(e) => setGameName(e.target.value)}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setGameName("");
                      document.getElementById("game-name")?.focus();
                    }}
                    edge="end"
                  >
                    <ClearIcon sx={{ backgroundColor: "#e0e0e0", borderRadius: "50%", padding: "4px" }} />
                  </IconButton>
                ),
              },
            }}
            disabled={isCreating}
          />
          {rooms && (
            <FormControl fullWidth>
              <InputLabel id="room-select-label">Add to Room</InputLabel>
              <Select
                labelId="room-select-label"
                id="room-id"
                label="Add to Room"
                value={inRoom ?? ""}
                onChange={(e) => {
                  setInRoom(e.target.value === "" ? undefined : e.target.value);
                }}
                disabled={isCreating}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {rooms.hostingRooms?.map((room) => (
                  <MenuItem key={room.roomId} value={room.roomId}>
                    {room.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {room && room.useChips && (
            <FormControl fullWidth>
              <InputLabel id="chips-per-entry-label">Chips per entry</InputLabel>
              <Select
                labelId="chips-per-entry-label"
                id="chips-per-entry"
                value={chipsPerEntry ?? ""}
                onChange={(e) =>
                  setChipsPerEntry(e.target.value === "" ? undefined : parseInt(e.target.value as string))
                }
                disabled={isCreating}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
              </Select>
            </FormControl>
          )}
          <p>Include a description of special rules for your game.</p>
          <TextField
            multiline
            rows={2}
            fullWidth={true}
            id="game-description"
            label="Game Description"
            variant="outlined"
            onChange={(e) => setGameDescription(e.target.value)}
            disabled={isCreating}
          />
        </FullSizeCard>

        {gameType === "Squares" && (
          <NewSquaresGame
            sport={sport}
            room={room}
            setNewGameData={setNewGameData}
            setGameTypeStrings={setGameTypeStrings}
          />
        )}
        {gameType === "Pickem" && (
          <NewPickemGame setNewGameData={setNewGameData} setGameTypeStrings={setGameTypeStrings} />
        )}

        <Box
          sx={
            _isInWebview()
              ? {
                  position: "absolute",
                  opacity: 0,
                  pointerEvents: "auto", // ensures the button remains clickable
                }
              : undefined
          }
        >
          <FullSizeCard>
            <Stack direction="row" justifyContent="center" spacing={2}>
              {!isSignedIn && (
                <Button variant="contained" onClick={() => setSignInDialogOpen(true)} disabled={isCreating}>
                  Sign In to Create Game
                </Button>
              )}
              <Button
                type="submit"
                variant={newGameData ? "contained" : "outlined"}
                disabled={!newGameData || !isSignedIn || isCreating}
              >
                {isCreating ? "Creating..." : "Create Game"}
              </Button>
            </Stack>
          </FullSizeCard>
        </Box>
      </form>
      {firebase && <SignInDialog open={signInDialogOpen} closeDialog={() => setSignInDialogOpen(false)} />}
    </>
  );
}
